.roadmap{
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 40px;
    padding-bottom: 40px;
    max-width: 90vw;
}

.roadmap-circle{
    position: relative;
    list-style-type: none;
    margin: auto;
    padding: 0;
}

.roadmap-item{
    display: block;
    position: relative;
    padding-left: 130px;
    margin-bottom: 100px;
}

.roadmap-desc{
    padding-left: 16px;
}

.roadmap-desc h1{
    font-size: 18px;
    font-family: aktiv-grotesk-extended, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: left;
}

.roadmap-desc p{
    
}

.roadmap-desc-back{
    position: absolute;
    left: 8em;
    width: 250px;
}


.oval-shape{
    position: absolute;
    left: 0;
    top: 0;
    background: #04020C;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #fff;
    text-align: center;
}

.oval-shape span{
    width: 100px;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    color: #fff;
    font-family: aktiv-grotesk-extended, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.bullet-item{
    display: block;
    padding-left: 40px;
    position: relative;
    margin-top: 20px;
}

.bullet{
    width: 28px;
    position: absolute;
    top: 5px;
    left: 0;
}

@media only screen and (max-width: 1100px){

}

@media only screen and (max-width: 1100px){
    .roadmap{
        background-image: none;
        padding-left: 0px;
    }
    .roadmap-circle{
        position: static;
        width: auto;
        height: auto;
    }
    .roadmap-item{
        display: block;
        position: relative;
        padding-left: 65px;
        transform: none !important;
        padding-bottom: 40px;
    }
    .oval-shape{
        position: absolute;
        left: 0;
        top: 0;
        width: 50px;
        height: 50px;
        background: none;
        border: 0;
        padding-top: 6px;
    }
    .oval-shape span{
        line-height: 1em;
        font-size: 1.3em;
    }
    .roadmap-desc-back{
        position: static;
        width: 100%;
    }
    .roadmap-desc-front{
        position: static;
        width: 100%;
    }
}


