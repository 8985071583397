.countdown {
    padding: 20px 40px;
    border-radius: 5;
    max-width: 600px;
    margin: 0 auto;
}

.countdown .row{
    display:flex;
    max-width: 500;
    margin: 0 auto;
}

.countdown .col{
    text-align: center;
    width: 25%;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    margin-right: 29;
    padding-right: 30;
}

.countdown .col-last{
    border-right: none;
    margin-right: 0;
    padding-right: 0;
}

.countdown .number{
    font-size: 3rem;
    color: #6ce5b7;
}

.countdown .label{
    font-size: 1rem;
    text-transform: uppercase;
}

@media only screen and (max-width: 760px){
    .countdown .number{
        font-size: 1.5em;
    }
    .countdown .label{
        font-size: 0.7em;
    }
}