.header-desktop{
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: rgba(0,0,0,0.8);
    align-items: center;
    display: flex;
}

.header-mobile{
    display: none;
}


.logo img{
    height: 50px;
    max-width: none;
}

.menu{
    flex-grow: 1;
}

.menu a{
    margin: 0 20px;
    color: #fff;
    font-size: 1.5em;
    text-decoration: none;
}

.header a:hover{
    text-decoration: underline;
}


.social a{
    display: inline-block;
    transition: all 0.2s ease-out;
    opacity: 1;
    margin-right: 15px;
    vertical-align: middle;
}

.social a:hover{
    opacity: 0.7;
}

.social img{
    width: 40px;
    height: 40px;
}

.burger{
    position: absolute;
    left: 15px;
    top: 15px;
}

.burger img{
    width: 50px;
    height: 50px;
}

.mobile-menu{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 300px;
    z-index: 10;
    transform: translateX(-100%);
    background-color: #000;
}

.mobile-menu-logo{
    width: 200px;
    margin: 10px 0 0 10px;
}

.mobile-menu ul{
    position: absolute;
    left: 20px;
    top: 140px;
    font-size: 1.8em;
}

.mobile-menu-close{
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0.5;
}

.mobile-menu-close img{
    width: 60px;
}

.mobile-menu .social{
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 960px){
    .header-desktop{
        display: none;
    }   
    .header-mobile{
        position: fixed;
        left: 0;
        right: 0;
        z-index: 9;
        background-color: rgba(0,0,0,0.8);
        display: block;
        backdrop-filter: blur(5px);
    }
    .mobile-menu{
        display: block;
    }
    
}